import { onCookieFirstServiceUpdate } from "./cookieFirst"
import { loadMatomoAndTrack } from "./matomo"
import { loadGoogleTagManager } from "./google-tag-manager"

export const initTracking = () => {
	onCookieFirstServiceUpdate((services) => {
		if (services.includes("matomo")) {
			loadMatomoAndTrack()
		}
	})

	onCookieFirstServiceUpdate((accepted) => {
		if (accepted.includes("google_tag_manager")) {
			loadGoogleTagManager()
		}
	})
}
