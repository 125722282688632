import { insertScript } from "./trackingHelper"

let enabled = false

const trackingId = "GTM-MJJJCR3J"

export const loadGoogleTagManager = () => {
	if (enabled) {
		return
	}

	enabled = true

	// @ts-ignore
	window["dataLayer"] = (window["dataLayer"] || []) as any[]
	// @ts-ignore
	window["dataLayer"].push({
		"gtm.start": new Date().getTime(),
		event: "gtm.js",
	} as any)

	insertScript(`https://www.googletagmanager.com/gtm.js?id=${trackingId}`)
}

export const sendDataLayer = <T extends { event: string }>(data: T) => {
	if (!enabled) {
		return
	}

	// @ts-expect-error
	window["dataLayer"] = window["dataLayer"] || []

	// @ts-expect-error
	window["dataLayer"].push(data)
}
